/* Import Nunito font */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
/* Import Google Material Icons */
@import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}


h1, h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.header-bar {
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.text-logo {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin: 0 0 0 50px;
}

.section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdfefe;
}

.section-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1250px;
}

.section-split.reverse {
  flex-direction: row-reverse;
}

.text-content {
  width: 50%;
}

.graphic-content-hero {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
}

.text-content.hero h1, h2 {
  font-size: 3rem;
}

.text-content.hero p {
  font-size: 1.25rem;
  margin-bottom: 40px;
}

.cta-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #4a235a;
  border-radius: 25px;
  font-size: 1.2rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #fff;
}

.service-list {
  list-style: none;
  padding: 0;
}

.service-list li {
  margin-bottom: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
}

.graphic-card {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.clients-graphic {
  max-width: 90%;
  height: auto;
}

.graphic-card-hero {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-graphic {
  max-width: 100%;
  height: auto;
}

.section.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: white;
}

.text-content-hero h1 {
  font-size: 4rem;
  color: #000;
}

.text-content-hero p {
  font-size: 1.2rem;
  color: #000;
}

.section.about {
  background-color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content-about h2 {
  font-size: 2.5rem;
  color: #000;
}

.text-content-about p {
  font-size: 1.2rem;
  color: #000;
}

.graphic-card-about {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.graphic-content-about {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
}

.section.clients {
  background-color: #eaf1f1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients-grid {
  display: flex;
  gap: 20px;
  overflow: hidden;
  max-width: 500px;
}

.clients-grid .graphic-card {
  animation: scrollClients 25s linear infinite;
}

@keyframes scrollClients {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.clients-graphic {
  max-width: 100%;
  height: auto;
}

.service-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-list ul li {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeInService 0.5s ease forwards;
  animation-delay: calc(0.1s * var(--service-index));
}

@keyframes fadeInService {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.material-icons {
  font-size: 1.5rem;
  margin-right: 10px;
}

.section.contact {
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.section.contact h2 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.section.contact p {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #fff;
  color: #4a235a;
  font-size: 1.2rem;
  border-radius: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #fff;
  color: #4a235a;
}

.footer-bar {
  background-color: #000;
  padding: 15px 0;
  border-top: 1px solid lightgray;
  text-align: center;
  color: white;
  max-height: 50px;
}

.footer-bar p {
  font-size: .95rem;
}

.material-icons {
  font-size: 1.5rem;
  margin-right: 10px;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 768px) {
  .section-split {
    flex-direction: column;
    text-align: center;
  }

  .text-content.hero h1, h2 {
    font-size: 1.9rem;
  }

  .text-content.hero p {
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .contact-form {
    max-width: 80%; /* Make form wider on tablets */
    padding: 0 20px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
    padding: 8px;
  }

  .contact-form button {
    font-size: 1rem;
    padding: 10px 15px;
  }

  .text-content, .graphic-content {
    width: 100%;
    margin-bottom: 20px;
  }

  .cta-button {
    padding: 8px 15px;
    font-size: 1rem;
  }

  h1, h2 {
    font-size: 1.9rem;
  }

  p {
    font-size: 1rem;
  }

  .service-list {
    flex-direction: column;
    gap: 10px;
    order: 2; /* Service list below the h2 */
    margin-bottom: 1.5rem;
  }

  .graphic-card {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .section.about .graphic-content-hero {
    flex-direction: column;
    order: 2;
    gap: 15px;
  }

  .section-split {
    flex-direction: column;
    text-align: center;
  }

  .graphic-content-about {
    width: 200px; /* Shrink the graphic */
    margin-bottom: 20px;
  }

  .text-content-about {
    width: 100%;
    padding: 0 20px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .section-split {
    flex-direction: column;
    text-align: center;
  }

  h1, h2 {
    font-size: 1.8rem;
    margin-top: .5rem;
  }

  p {
    font-size: .9rem;
  }

  .cta-button {
    padding: 8px 15px;
    font-size: 1rem;
  }

  .graphic-content, .text-content {
    width: 100%;
    text-align: center;
  }

  .graphic-card {
    max-width: 100%;
  }

  .service-list {
    gap: 1px;
    order: 2; /* Service list below the h2 */
  }

  .service-list ul li {
    font-size: 1rem;
    margin-bottom: auto;
  }

  .section.about .graphic-content-hero {
    gap: 15px;
    order: 2;
    flex-direction: column;
    display: none; /* Hide on smaller mobile screens */
  }

  .text-content-about {
    width: 100%;
    padding: 0 20px;
  }

}

/* Contact Form Responsiveness */

/* For tablet and medium screens */
@media (max-width: 768px) {
  .contact-form {
    max-width: 80%; /* Make form wider on tablets */
    padding: 0 20px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
    padding: 8px;
  }

  .contact-form button {
    font-size: 1rem;
    padding: 10px 15px;
  }
  .section.contact h2 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

/* For mobile and smaller screens */
@media (max-width: 480px) {
  .contact-form {
    max-width: 80%; /* Make form full width on smaller screens */
    padding: 0 10px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.8rem;
    padding: 7px;
  }

  .contact-form button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
  .section.contact h2 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .section.contact p {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .footer-bar p {
    font-size: .75rem;
  }
}
